import {
	ShoppingOutlined,
	DashboardOutlined,
	FileUnknownOutlined,
} from "@ant-design/icons";
import { EMPRESA_APP_PREFIX_PATH } from "configs/AppConfig";
import {
	PermissaoDashboardEmpresaEnum,
	PermissaoGrupoUsuarioEmpresaEnum,
	PermissaoPesquisaEmpresaEnum,
	PermissaoProdutoEmpresaEnum,
	PermissaoUsuarioEmpresaEnum,
} from "enum/permissao-empresa-enum";
import {
	UsergroupAddOutlined,
	UserOutlined,
	InfoCircleOutlined,
} from "@ant-design/icons";
import { PermissaoProdutoEnum } from "enum/permissao-enum";

const dashBoardNavTree = [
	{
		key: "dashboard",
		path: `${EMPRESA_APP_PREFIX_PATH}/dashboard`,
		title: "dashboard",
		icon: DashboardOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoDashboardEmpresaEnum.VISUALIZAR,
	},
	{
		key: "pesquisas-produto-empresa",
		path: `${EMPRESA_APP_PREFIX_PATH}/pesquisas-produto`,
		title: "pesquisas-produto-empresa",
		icon: FileUnknownOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoPesquisaEmpresaEnum.VISUALIZAR,
	},
	{
		key: "pesquisas-subcategoria-empresa",
		path: `${EMPRESA_APP_PREFIX_PATH}/pesquisas-subcategoria`,
		title: "pesquisas-subcategoria-empresa",
		icon: FileUnknownOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoPesquisaEmpresaEnum.VISUALIZAR,
	},
	{
		key: "lista-produtos",
		path: `${EMPRESA_APP_PREFIX_PATH}/lista-produtos`,
		title: "lista-produtos",
		icon: ShoppingOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoProdutoEnum.VISUALIZAR,
	},
	{
		key: "benchmark",
		path: `${EMPRESA_APP_PREFIX_PATH}/benchmark`,
		title: "benchmark",
		icon: InfoCircleOutlined,
		breadcrumb: false,
		submenu: [],
	},
	{
		key: "gerenciamento-usuario",
		title: "gerenciamento-usuario",
		breadcrumb: false,
		submenu: [
			{
				key: "lista-usuarios",
				path: `${EMPRESA_APP_PREFIX_PATH}/lista-usuarios`,
				title: "lista-usuarios",
				icon: UserOutlined,
				breadcrumb: false,
				submenu: [],
				permissao: PermissaoUsuarioEmpresaEnum.VISUALIZAR,
			},
			{
				key: "lista-grupos-usuario",
				path: `${EMPRESA_APP_PREFIX_PATH}/lista-grupos-usuario`,
				title: "lista-grupos-usuario",
				icon: UsergroupAddOutlined,
				breadcrumb: false,
				submenu: [],
				permissao: PermissaoGrupoUsuarioEmpresaEnum.VISUALIZAR,
			},
		],
	},
];

const empresaNavigationConfig = [...dashBoardNavTree];

export default empresaNavigationConfig;
