const dev = {
	API_ENDPOINT_URL: "http://localhost:8080/foodtest/api",
	SITE_BASE_URL: "http://localhost:3000/#",
	PUBLIC_PREFIX_PATH: "",
};

const prod = {
	API_ENDPOINT_URL:
		"https://foodtest-back-ri6ip.ondigitalocean.app/foodtest-back2/foodtest/api",
	SITE_BASE_URL: "https://plataforma.foodtest.com.br/#",
	PUBLIC_PREFIX_PATH: "",
};

const test = {
	API_ENDPOINT_URL: "https://homolog.dimo.com.br/foodtest-back/api",
	SITE_BASE_URL: "https://homolog.dimo.com.br/foodtest/#",
	PUBLIC_PREFIX_PATH: "/foodtest",
};

const getEnv = () => {
	switch (process.env.REACT_APP_ENV) {
		case "development":
			return dev;
		case "production":
			return prod;
		case "test":
			return test;
		default:
			return dev;
	}
};

export const env = getEnv();
