export const PermissaoUsuarioEnum = {
	VISUALIZAR: "VISUALIZAR_USUARIO",
	CRIAR: "CRIAR_USUARIO",
	EXCLUIR: "EXCLUIR_USUARIO",
};

export const PermissaoGrupoUsuarioEnum = {
	VISUALIZAR: "VISUALIZAR_GRUPO_USUARIO",
	CRIAR: "CRIAR_GRUPO_USUARIO",
	EXCLUIR: "EXCLUIR_GRUPO_USUARIO",
};

export const PermissaoCargoEnum = {
	VISUALIZAR: "VISUALIZAR_CARGO",
	CRIAR: "CRIAR_CARGO",
	EXCLUIR: "EXCLUIR_CARGO",
};

export const PermissaoSegmentoProfissionalEnum = {
	VISUALIZAR: "VISUALIZAR_SEGMENTO_PROFISSIONAL",
	CRIAR: "CRIAR_SEGMENTO_PROFISSIONAL",
	EXCLUIR: "EXCLUIR_SEGMENTO_PROFISSIONAL",
};

export const PermissaoEmpresaEnum = {
	VISUALIZAR: "VISUALIZAR_EMPRESA",
	CRIAR: "CRIAR_EMPRESA",
	EXCLUIR: "EXCLUIR_EMPRESA",
};

export const PermissaoCategoriaProdutoEnum = {
	VISUALIZAR: "VISUALIZAR_CATEGORIA_PRODUTO",
	CRIAR: "CRIAR_CATEGORIA_PRODUTO",
	EXCLUIR: "EXCLUIR_CATEGORIA_PRODUTO",
};

export const PermissaoProdutoEnum = {
	VISUALIZAR: "VISUALIZAR_PRODUTO",
	CRIAR: "CRIAR_PRODUTO",
	EXCLUIR: "EXCLUIR_PRODUTO",
};

export const PermissaoRespostaPesquisaEnum = {
	VISUALIZAR: "VISUALIZAR_RESPOSTA_PESQUISA",
	CRIAR: "CRIAR_RESPOSTA_PESQUISA",
	EXCLUIR: "EXCLUIR_RESPOSTA_PESQUISA",
};
