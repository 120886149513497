import fetch from "auth/FetchInterceptor";
import store from "redux/store";
import { UPDATE_NOTIFICATIONS } from "redux/constants/Menu";
import {
	UPDATE_PERMISSOES_ADMIN,
	UPDATE_PERMISSOES_EMPRESA,
	UPDATE_PERMISSOES_TESTADOR,
} from "redux/constants/Permissoes";
import {
	UPDATE_USER_ADMIN,
	UPDATE_USER_EMPRESA,
	UPDATE_USER_TESTADOR,
} from "redux/constants/User";
import { idea } from "react-syntax-highlighter/dist/esm/styles/hljs";

const MenuService = {};

/*
    Injeta na store do usuário os dados do menu
*/
MenuService.populateMenuData = async function () {
	const menuData = await MenuService.getMenuData();

	const url = window.location.href;

	const isAdm = url.includes("adm-app") || url.includes("adm-auth");
	const isEmpresa =
		url.includes("empresa-app") || url.includes("empresa-auth");
	const isTestador =
		url.includes("testador-app") || url.includes("testador-auth");

	if (isAdm) {
		store.dispatch({
			type: UPDATE_USER_ADMIN,
			name: menuData.usuario.nome,
			role: "Usuário",
			id: menuData.usuario.id,
		});

		store.dispatch({
			type: UPDATE_PERMISSOES_ADMIN,
			permissoes: menuData.permissoes,
		});
	}

	if (isEmpresa) {
		store.dispatch({
			type: UPDATE_USER_EMPRESA,
			name: menuData.usuario.nome,
			role: "Usuário",
			id_empresa: menuData.usuario.id,
			plano: menuData.usuario.plano,
		});

		store.dispatch({
			type: UPDATE_PERMISSOES_EMPRESA,
			permissoes: menuData.permissoes,
		});
	}

	if (isTestador) {
		store.dispatch({
			type: UPDATE_USER_TESTADOR,
			name: menuData.usuario.nome,
			url_avatar: menuData.usuario.url_avatar,
			email_testador: menuData.usuario.email,
			pontuacao: menuData.usuario.pontuacao,
			id: menuData.usuario.id,
			role: "Usuário",
		});

		store.dispatch({
			type: UPDATE_PERMISSOES_TESTADOR,
			permissoes: menuData.permissoes,
		});
	}

	store.dispatch({
		type: UPDATE_NOTIFICATIONS,
		empresasComErros: menuData.notificacoes.total_empresas_com_erros,
	});
};

// TODO: após clonar o skeleton, ajustar essa função
MenuService.getMenuData = function () {
	return fetch({
		url: "/v1/rpc/menu",
		method: "get",
	});
};

export default MenuService;
