import React from "react";
import { connect } from "react-redux";
import { Layout } from "antd";
import { toggleCollapsedNav, onMobileNavToggle } from "redux/actions/Theme";
import utils from "utils";
import NavProfile from "./NavProfile";
import {
	ADM_APP_PREFIX_PATH,
	APP_NAME,
	PUBLIC_PREFIX_PATH,
} from "../../configs/AppConfig";
import NavNotification from "./NavNotification";
import { useHistory } from "react-router-dom";

const { Header } = Layout;

export const HeaderNavAdmin = (props) => {
	const { headerNavColor, currentTheme } = props;

	const history = useHistory();

	const mode = () => {
		if (!headerNavColor) {
			return utils.getColorContrast(
				currentTheme === "dark" ? "#000000" : "#ffffff",
			);
		}
		return utils.getColorContrast(headerNavColor);
	};
	const navMode = mode();

	return (
		<Header
			className={`app-header ${navMode}`}
			style={{
				backgroundColor: "#307FE2",
				display: "flex",
				alignItems: "center",
				justifyContent: "space-between",
				paddingLeft: "24px",
				paddingRight: "24px",
			}}
		>
			<div
				style={{
					width: "33%",
				}}
			/>

			{/* Logo centralizada */}
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					width: "33%",
				}}
			>
				<img
					src={`${PUBLIC_PREFIX_PATH}/logo-white.svg`}
					alt={`${APP_NAME} logo`}
					style={{
						maxWidth: "200px", // Limita o tamanho máximo do logo
						maxHeight: "64px", // Ajusta a altura máxima do logo
						objectFit: "contain",
						cursor: "pointer",
					}}
					onClick={() => {
						history.push(`${ADM_APP_PREFIX_PATH}/dashboard`);
					}}
				/>
			</div>

			{/* NavProfile à direita */}
			<div
				style={{
					width: "33%",
					display: "flex",
					justifyContent: "flex-end",
					alignItems: "center",
				}}
			>
				<NavProfile {...props} />
			</div>
		</Header>
	);
};

const mapStateToProps = ({ theme }) => {
	const {
		navCollapsed,
		navType,
		headerNavColor,
		mobileNav,
		currentTheme,
		direction,
	} = theme;
	return {
		navCollapsed,
		navType,
		headerNavColor,
		mobileNav,
		currentTheme,
		direction,
	};
};

export default connect(mapStateToProps, {
	toggleCollapsedNav,
	onMobileNavToggle,
})(HeaderNavAdmin);
