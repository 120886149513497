import React, { lazy, Suspense, useEffect, useRef } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { EMPRESA_APP_PREFIX_PATH } from "configs/AppConfig";
import JwtAuthService from "../../../services/JwtAuthService";
import MenuService from "../../../services/MenuService";

export const EmpresaAppViews = () => {
	let intervalId = useRef(null);

	const populateMenu = async () => {
		try {
			if (JwtAuthService.isLogged()) {
				await MenuService.populateMenuData();
			}
		} catch (e) {}

		intervalId.current = setInterval(async () => {
			if (JwtAuthService.isLogged()) {
				await MenuService.populateMenuData();
			}
		}, 30000);
	};

	useEffect(() => {
		populateMenu();

		return () => {
			clearInterval(intervalId.current);
		};
	}, []);

	return (
		<Suspense fallback={<Loading cover="content" />}>
			<Switch>
				<Route
					path={`${EMPRESA_APP_PREFIX_PATH}/dashboard`}
					component={lazy(() => import(`./dashboard`))}
				/>
				<Route
					path={`${EMPRESA_APP_PREFIX_PATH}/lista-produtos`}
					component={lazy(() => import(`./lista-produtos`))}
				/>
				<Route
					path={`${EMPRESA_APP_PREFIX_PATH}/detalhes-produto/:id`}
					component={lazy(() => import(`./detalhes-produto`))}
				/>

				<Route
					path={`${EMPRESA_APP_PREFIX_PATH}/produto/:id`}
					component={lazy(() => import(`./produto`))}
				/>
				<Route
					path={`${EMPRESA_APP_PREFIX_PATH}/produto`}
					component={lazy(() => import(`./produto`))}
				/>

				<Route
					path={`${EMPRESA_APP_PREFIX_PATH}/lista-usuarios`}
					component={lazy(() => import(`./lista-usuarios`))}
				/>
				<Route
					path={`${EMPRESA_APP_PREFIX_PATH}/usuario/:id`}
					component={lazy(() => import(`./usuario`))}
				/>
				<Route
					path={`${EMPRESA_APP_PREFIX_PATH}/usuario`}
					component={lazy(() => import(`./usuario`))}
				/>
				<Route
					path={`${EMPRESA_APP_PREFIX_PATH}/lista-grupos-usuario`}
					component={lazy(() => import(`./lista-grupos-usuario`))}
				/>
				<Route
					path={`${EMPRESA_APP_PREFIX_PATH}/grupo-usuario/:id`}
					component={lazy(() => import(`./grupo-usuario`))}
				/>
				<Route
					path={`${EMPRESA_APP_PREFIX_PATH}/grupo-usuario`}
					component={lazy(() => import(`./grupo-usuario`))}
				/>

				<Route
					path={`${EMPRESA_APP_PREFIX_PATH}/pesquisas-produto`}
					component={lazy(() => import(`./pesquisas-produto`))}
				/>
				<Route
					path={`${EMPRESA_APP_PREFIX_PATH}/pesquisas-subcategoria`}
					component={lazy(() => import(`./pesquisas-subcategoria`))}
				/>
				<Route
					path={`${EMPRESA_APP_PREFIX_PATH}/detalhes-pesquisa-produto/:idPesquisa/:idProduto`}
					component={lazy(
						() => import(`./detalhes-pesquisa-produto`),
					)}
				/>
				<Route
					path={`${EMPRESA_APP_PREFIX_PATH}/detalhes-pesquisa-subcategoria/:idPesquisa/:idProduto`}
					component={lazy(
						() => import(`./detalhes-pesquisa-subcategoria`),
					)}
				/>
				<Route
					path={`${EMPRESA_APP_PREFIX_PATH}/lista-usuarios`}
					component={lazy(() => import(`./lista-usuarios`))}
				/>
				<Route
					path={`${EMPRESA_APP_PREFIX_PATH}/usuario/:id`}
					component={lazy(() => import(`./usuario`))}
				/>
				<Route
					path={`${EMPRESA_APP_PREFIX_PATH}/usuario`}
					component={lazy(() => import(`./usuario`))}
				/>
				<Route
					path={`${EMPRESA_APP_PREFIX_PATH}/lista-grupos-usuario`}
					component={lazy(() => import(`./lista-grupos-usuario`))}
				/>
				<Route
					path={`${EMPRESA_APP_PREFIX_PATH}/grupo-usuario/:id`}
					component={lazy(() => import(`./grupo-usuario`))}
				/>
				<Route
					path={`${EMPRESA_APP_PREFIX_PATH}/grupo-usuario`}
					component={lazy(() => import(`./grupo-usuario`))}
				/>
				<Route
					path={`${EMPRESA_APP_PREFIX_PATH}/benchmark`}
					component={lazy(() => import(`./benchmark`))}
				/>

				<Redirect
					from={`${EMPRESA_APP_PREFIX_PATH}`}
					to={`${EMPRESA_APP_PREFIX_PATH}/dashboard`}
				/>
			</Switch>
		</Suspense>
	);
};

export default React.memo(EmpresaAppViews);
